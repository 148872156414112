<template>
  <f7-page class="notifications notif-title">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_NOTIFICATIONS')" type="back" :search="false" :cart="false" />
    </template>

    <template v-if="Notification">
      <div style="display: flex;align-items: center;justify-content: center;padding-top:50px;padding-bottom:50px;">
        <img style="width:200px;" :src="require('@/assets/images/notif_icon.png')" loading="lazy" />
      </div>
      <img style="width:100%;" :src="require('@/assets/images/notif_mask.png')" loading="lazy" />
      <div class="notif-content">
        <h3>{{ Notification.Title }}</h3>
        <div class="line1"></div>
        <div class="line2"></div>
        <p>{{ Notification.Content }}</p>
      </div>
    </template>
  </f7-page>
</template>

<style scoped>
.notif-view {
  padding: 0px;
}

.notif-content h3 {
  margin-top: 20px;
  font-size: 20px;
  color: #0b4d8f;
}

.line1 {
  background: #0a223c;
  width: 200px;
  height: 5px;
  margin-top: 10px;
}

.line2 {
  background: #0a223c;
  width: 150px;
  height: 5px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.notif-content {
  padding: 30px;
  padding-top: 10px;
  background: url('../../assets/images/notif_bg.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #f3f3f3;
  min-height: calc(100% - 370px);
  margin-top: -8px;
}
</style>

<script>
import { defineComponent, computed, ref, onMounted, defineAsyncComponent } from 'vue'
import { useStore } from '@/store'
import { get } from '@/utils/axios'
// import CommonNavigationComponent from "@/components/navigations/CommonNavigationComponent.vue";
const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue'))

export default defineComponent({
  name: 'ViewNotificationPage',
  components: {
    ProductNavigationComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    const Notification = ref(null)

    const getNotification = async () => {
      if (props?.f7route?.params?.key) {
        let ret = await get('/user/notifications', { key: props?.f7route?.params?.key })
        Notification.value = ret.List[0]
      }
    }

    onMounted(() => {
      getNotification()
    })

    return { Notification }
  }
})
</script>

<style>
.empty-container {
  margin-top: 150px;
}
</style>
